<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/load-public-sections";

export default {
  name: "CookiePolicy",

  metaInfo: {
    title: "Nosotros",
  },

  extends: View,

  mixins: [LoadSections(["legal", "cookie-policy"])],
};
</script>
